import { QuickSearchToolbar } from '../components/filter/GlobalFilter';
import { GridToolbar } from '@mui/x-data-grid';

export const CustomToolbar = () => (
    <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
        <GridToolbar/>
        <div style={{ marginLeft: 'auto' }}>
            <QuickSearchToolbar />
        </div>
    </div>
);