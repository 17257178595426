import { useForm } from '../../hooks/useForm'
import {  useDispatch, useSelector } from 'react-redux';
import { filterDeliveryDonwloadN } from '../../actions/deliveryNotes';
import './delivery.css';
import RolesConstants  from '../../constant/roles';
import { BtnLogout } from '../ui/BtnLogout';
import { BtnBack } from '../ui/BtnBack';
import { BtnReload } from '../ui/BtnReload';
import { Link } from 'react-router-dom';
import { Collapse, Space, Tooltip } from 'antd';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useState } from 'react';
import { CustomToolbar } from '../../helpers/customtoolbar';
import { BtnDeliveryOrder } from '../ui/BtnDeliveryOrder';
import { BtnDeliveryNote } from '../ui/BtnDeliveryNote';
import { BtnDownload } from '../ui/BtnDownload';
import { BtnDownloads } from '../ui/BtnDownloads';

const { Panel } = Collapse;
const secOrder = 1;
const secNote = 2;

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossorigin="anonymous"></script>

const columns = [
    {
        headerName: 'LINEA',
        field: 'line',
        flex: 1,
    },
    {
        headerName: 'CÓDIGO ARTÍCULO',
        field: 'narticle',
        flex: 2,
    },
    {
        headerName: 'DESCRIPCIÓN',
        field: 'description',
        renderCell: (record) =>  (
            <Tooltip placement="topLeft" title={record.row.description} >
                <span className="table-cell-trucate">{record.row.description}</span>
            </Tooltip>
        ),
        flex: 2,
    },
    {
        headerName: 'RECIBIDAS',
        field: 'received',
        flex: 1,
    },
    {
        headerName: 'NO RECIBIDAS',
        field: 'noreceived',
        flex: 1,
    },
    {
        headerName: 'Nº PEDIDO',
        field: 'ordercode',
        flex: 2,
        renderCell: (record) => (
            <Space size="middle">
                <Link to={`/delivery/download/${record.row.ordercode} - ${record.row?.store_id} - ${secOrder}`}>
                    <BtnDeliveryOrder  order= { record.row}/>
                </Link>
            </Space>
        ),
    },
    {
        headerName: 'Nº ALBARAN',
        field: 'deliverynote',
        flex: 2,
        renderCell: (record) => (
            <Space size="middle">
                <Link to={`/delivery/download/${record.row.deliverynote} - ${record.row.store_id} - ${secNote}`}>
                    <BtnDeliveryNote  delivery= { record.row}/>
                </Link>
            </Space>
        ),
    },
    {
        headerName: 'PROVEEDOR',
        field: 'providercode',
        flex: 2,
    },
    {
        headerName: 'NOMBRE PROVEEDOR',
        field: 'providername',
        flex: 2,
    },
    {
        headerName: 'Acción',
        field: 'action',
        flex: 1,
        renderCell: (record) => (
            <Space size="middle">
                <BtnDownload  delivery= { record.row}/>
            </Space>
        ),
    },
]

export const DeliveryNumberScreen = ( {history} ) => {

    const { deliveryN } = useSelector(state => state.delivery);
    const { stores } = useSelector(state => state.store);
    const { rol } = useSelector(state => state.auth)
    const dispatch = useDispatch();

    const [pageSize, setPageSize] = useState(10);

    const [ formRegisterValues, handleRegisterInputChange ] = useForm({
        store_id: '',
        downloadcode:'',
        narticle: '',
        ordercode: '',
        deliverynote: '',
        providercode: '',
    });

    const { store_id, downloadcode, narticle, ordercode, deliverynote, providercode } = formRegisterValues

    const handleFilter = (e) => {
            e.preventDefault();

            dispatch( filterDeliveryDonwloadN(formRegisterValues) )
    }


    return (
        <div >
            <section id="general-hdr">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <BtnBack history={history}/>
                            <h1 className="section-name">Albaranes Por Número de Descarga</h1>
                        </div>
                        <div className="col-auto"><BtnLogout/></div>
                    </div>
                </div>
            </section>
            <section id="filters-zone">
                <div className="container-fluid pt-2 pb-2 mb-2">

                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <Collapse accordion defaultActiveKey={['1']}>
                            <Panel header="Filtrar productos" key="1">
                            <div id="flush-collapseOne" className="accordion-collapse " aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">

                                    <form onSubmit={ handleFilter } className="mmk-form">
                                        { (rol !== RolesConstants.STORE) &&
                                        <div className="row mb-2">
                                            <div className="col">
                                                <label className="form-label" >Tienda </label>
                                                <select
                                                    name="store_id"
                                                    defaultValue={store_id}
                                                    className='form-select form-select-md shop-sel'
                                                    onChange= { handleRegisterInputChange }
                                                >
                                                    <option key={0} value=''>TODAS LAS TIENDAS</option>
                                                    {
                                                        (
                                                            stores.map((item,i)=>(
                                                            <option key={"stores"+i} value={item.id}>{item.code} - {item.name}</option>
                                                        ))
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        }

                                        <div className="row mb-2">
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Número de Descarga</label>
                                                <input
                                                    type="text"
                                                    name="downloadcode"
                                                    value= { downloadcode }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Código de artículo</label>
                                                <input
                                                    type="text"
                                                    name="narticle"
                                                    value= { narticle }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Código de pedido</label>
                                                <input
                                                    type="text"
                                                    name="ordercode"
                                                    value= { ordercode }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Albaran proveedor</label>
                                                <input
                                                    type="text"
                                                    name="deliverynote"
                                                    value= { deliverynote }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Código proveedor</label>
                                                <input
                                                    type="text"
                                                    name="providercode"
                                                    value= { providercode }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                        </div>
                                        <div className="row justify-content-end mb-2">
                                            <div className="col-12 col-lg-3">
                                                <div className="d-grid gap-2">
                                                    <button type="submit" className="btn btn-primary">APLICAR</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            </Panel>
                        </Collapse>
                    </div>
                </div>
            </section>
            <div className='table-container'>
                <BtnReload props={'deliveryN'} data={formRegisterValues}/>
                <BtnDownloads props={'deliveryN'} data={deliveryN}/>
                <div className="table-style-stock margin-top">
                    <DataGrid
                        rows={deliveryN ?? []}
                        columns={columns}
                        getRowId={(row) => `${row.narticle}-${Math.random().toString(36).substr(2, 9)}`}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        components={{ Toolbar: CustomToolbar  }}
                    />
                </div>
            </div>
        </div>
    )
}