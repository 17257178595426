import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import "./filter.css";

export function QuickSearchToolbar() {
    return (
        <GridToolbarQuickFilter
            placeholder="Buscar..."
             className="quick-filter"
        />
    );
  }