import { useForm } from '../../hooks/useForm'
import {  useDispatch, useSelector } from 'react-redux';
import { filterSscc } from '../../actions/sscc';
import './sscc.css';
import RolesConstants  from '../../constant/roles';
import moment from 'moment';
import { BtnLogout } from '../ui/BtnLogout';
import { BtnBack } from '../ui/BtnBack';
import { BtnReload } from '../ui/BtnReload';
import { Tooltip, Collapse } from 'antd';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useState } from 'react';
import { CustomToolbar } from '../../helpers/customtoolbar';

const { Panel } = Collapse;

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossorigin="anonymous"></script>

const columns = [
    {
        headerName: 'TIENDA',
        field: 'store',
        flex: 1,
    },
    {
        headerName: 'CÓDIGO PROVEEDOR',
        field: 'providercode',
        flex: 1,
    },
    {
        headerName: 'Nº PEDIDO',
        field: 'ordercode',
        flex: 1,
    },
    {
        headerName: 'FECHA DE DESCARGA',
        field: 'ddate',
        flex: 1,
        valueFormatter: params =>
        moment(params?.value).format("DD/MM/YYYY HH:mm:ss"),
        renderCell: (record) =>  (
            <Tooltip placement="topLeft" title={moment(record.row.ddate).format("DD/MM/YYYY HH:mm:ss")} >
                <span className="table-cell-trucate">{moment(record.row.ddate).format("DD/MM/YYYY HH:mm:ss")}</span>
            </Tooltip>
        ),
    },
    {
        headerName: 'LEM',
        field: 'lem',
        flex: 1,

    },
    {
        headerName: 'CÓDIGO ARTÍCULO',
        field: 'narticle',
        flex: 1,
    },
    {
        headerName: 'CANTIDAD',
        field: 'qty',
        flex: 1,
    },
    {
        headerName: 'SSCC',
        field: 'sscc',
        flex: 1,
    },
]

export const SsccScreen = ( {history} ) => {

    const { ssccData } = useSelector(state => state.sscc);
    const { stores } = useSelector(state => state.store);
    const { rol } = useSelector(state => state.auth)
    const dispatch = useDispatch();

    const [pageSize, setPageSize] = useState(10);

    const [ formRegisterValues, handleRegisterInputChange ] = useForm({
        store_id: '',
        sscc:'',
        narticle: '',
        providercode: '',
        lem: '',
        ordercode: '',

    });

    const { store_id, sscc, narticle, providercode, lem, ordercode } = formRegisterValues

    const handleFilter = (e) => {
        e.preventDefault();

        dispatch( filterSscc(formRegisterValues) )
    }

    return (
        <div >
            <section id="general-hdr">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <BtnBack history={history}/>
                            <h1 className="section-name">Búsqueda SSCC</h1>
                        </div>
                        <div className="col-auto"><BtnLogout/></div>
                    </div>
                </div>
            </section>
            <section id="filters-zone">
                <div className="container-fluid pt-2 pb-2 mb-2">

                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <Collapse accordion defaultActiveKey={['1']}>
                            <Panel header="Filtrar productos" key="1">
                            <div id="flush-collapseOne" className="accordion-collapse " aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">

                                    <form onSubmit={ handleFilter } className="mmk-form">
                                        { rol === RolesConstants.ADMIN &&
                                        <div className="row mb-2">
                                            <div className="col">
                                                <label className="form-label" >Tienda </label>
                                                <select
                                                    name="store_id"
                                                    defaultValue={store_id}
                                                    className='form-select form-select-md shop-sel'
                                                    onChange= { handleRegisterInputChange }
                                                >
                                                    <option key={0} value=''>TODAS LAS TIENDAS</option>
                                                    {
                                                        (
                                                            stores.map((item,i)=>(
                                                            <option key={"stores"+i} value={item.id}>{item.code} - {item.name}</option>
                                                        ))
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        }

                                        <div className="row mb-2">
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Código Sscc</label>
                                                <input
                                                    type="text"
                                                    name="sscc"
                                                    value= { sscc }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Código de artículo</label>
                                                <input
                                                    type="text"
                                                    name="narticle"
                                                    value= { narticle }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Código proveedor</label>
                                                <input
                                                    type="text"
                                                    name="providercode"
                                                    value= { providercode }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Código LEM</label>
                                                <input
                                                    type="text"
                                                    name="lem"
                                                    value= { lem }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                            <div className="col-12 col-lg">
                                                <label className="form-label">Código de pedido</label>
                                                <input
                                                    type="text"
                                                    name="ordercode"
                                                    value= { ordercode }
                                                    className='form-control'
                                                    onChange= { handleRegisterInputChange }
                                                />
                                            </div>
                                        </div>
                                        <div className="row justify-content-end mb-2">
                                            <div className="col-12 col-lg-3">
                                                <div className="d-grid gap-2">
                                                    <button type="submit" className="btn btn-primary">APLICAR</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            </Panel>
                        </Collapse>
                    </div>
                </div>
            </section>
            <div className='table-container'>
                <BtnReload props={'ssccData'} data={formRegisterValues}/>
                <div className="table-style-stock margin-top">
                    <DataGrid
                        rows={ssccData ?? []}
                        columns={columns}
                        getRowId={(row) => `${row.narticle}-${Math.random().toString(36).substr(2, 9)}`}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        components={{ Toolbar: CustomToolbar }}
                    />
                </div>
            </div>
        </div>
    )
}