import { fetchDocuments } from "../helpers/fetch";
import { startLogout } from "./auth";
import Swal from "sweetalert2";

export const filterDownloadSheet = (data) => {
    return async (dispatch) => {
        const resp = await fetchDocuments(`downloadsheet/pdf`, data, () => dispatch(startLogout()));
        if( Array.isArray(resp.documents) && resp.documents.length === 0 ){
            const msg = 'No hay archivo disponible para descargar.';
            Swal.fire('Descarga', msg, 'info');
        }
    }
};